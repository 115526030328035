import * as React from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";

import { FaqSearchField } from "../styles";

import { Box } from "@components/box";
import { Paragraph } from "@components/typography";

import searchIcon from "@images/ic-search.svg";

const nodeIncludesSearchTerm = (node: any, searchTerm: string) => {
  let answerSpan = document.createElement("span");
  answerSpan.innerHTML = node.answer.answer;
  const answerText = answerSpan.textContent || answerSpan.innerText;
  return node.question.includes(searchTerm) || answerText.includes(searchTerm);
};

export const FaqSearch = () => {
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const { allContentfulFaqQuestion } = useStaticQuery(graphql`
    {
      allContentfulFaqQuestion {
        nodes {
          slug
          category {
            name
            slug
          }
          question
          answer {
            answer
          }
        }
      }
    }
  `);

  return (
    <Box position="relative" className="mb-[66px]">
      <Box
        display="flex"
        className={`w-full bg-interface-100 h-[57px] items-center p-[11px] ${
          searchTerm.length > 0 ? "rounded-t-[11px]" : "rounded-[11px]"
        }`}
      >
        <img src={searchIcon} />{" "}
        <FaqSearchField
          className="w-full"
          placeholder="Have a question? Search for answers"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(e.target.value)
          }
        />
      </Box>
      {searchTerm.length > 0 ? (
        <Box
          display="flex"
          position="absolute"
          className="bg-interface-50 shadow-sm flex-col w-full"
        >
          {allContentfulFaqQuestion.nodes.map((node: any, key:number) =>
            nodeIncludesSearchTerm(node, searchTerm) ? (
              <Box
                key={key}
                display="flex"
                className="hover:bg-interface-100 flex-row h-[60px] items-center"
                onClick={() =>
                  navigate(`/faq/${node.category.slug}/${node.slug}`)
                }
              >
                <Box className="px-[20px]">
                  <Paragraph>{`${node.question} - ${node.categoryName}`}</Paragraph>
                </Box>
              </Box>
            ) : null
          )}
        </Box>
      ) : null}
    </Box>
  );
};
