import * as React from "react";
import { graphql } from "gatsby";

import { GrayPhrase, PageHeading, Paragraph } from "@components/typography";
import { PageLayout, SEO } from "@components/page-layout";
import { GatsbyLink } from "@components/gatsby-link";
import { Box } from "@components/box";
import arrowRight from "@images/ic-arrow-long-right-gray.svg";
import { FaqAnswer } from "@components/faq";
import { FaqSearch } from "@components/faq-search";

const FaqQuestionTemplate = (props: any) => {
  const { contentfulFaqQuestion } = props.data;

  return (
    <PageLayout>
      <SEO title={contentfulFaqQuestion.metaTitle} />
      <Box className="text-center max-w-[660px] mx-auto mb-[66px] mt-[8.25rem] md:mt-[4rem] sm:px-[22px]">
        <PageHeading className="!w-full mb-[22px]">FAQ</PageHeading>
        <Paragraph>
          <GrayPhrase>
            Curabitur quam erat, sollicitudin et congue quis, elementum eu
            sapien. Pellentesque ut massa nec nunc sagittis condimentum eu at
            arcu.
          </GrayPhrase>
        </Paragraph>
      </Box>
      <Box
        display="flex"
        className="mx-auto max-w-[1320px] flex-col justify-between lg:px-[22px] md:flex-wrap md:justify-evenly"
      >
        <FaqSearch />
        <Box
          display="flex"
          className="text-[17px] mb-[66px] items-center sm:flex-col sm:items-start"
        >
          <GatsbyLink to="/faq">FAQ</GatsbyLink>
          <img src={arrowRight} className="mx-[11px] sm:mx-0 sm:hidden" />
          <GatsbyLink to={`/faq/${contentfulFaqQuestion.category.slug}`}>
            {contentfulFaqQuestion.category.name}
          </GatsbyLink>
          <img src={arrowRight} className="mx-[11px] sm:mx-0 sm:hidden" />
          <Paragraph>{contentfulFaqQuestion.question}</Paragraph>
        </Box>
        <Box display="flex" className="w-full">
          <FaqAnswer
            categoryName={contentfulFaqQuestion.category.name}
            categorySlug={contentfulFaqQuestion.category.slug}
            question={contentfulFaqQuestion.question}
            answer={contentfulFaqQuestion.answer.answer}
          />
        </Box>
      </Box>
    </PageLayout>
  );
};

export default FaqQuestionTemplate;

export const pageQuery = graphql`
  query FAQQuestionBySlug($slug: String!) {
    contentfulFaqQuestion(slug: { eq: $slug }) {
      slug
      category {
        name
        slug
      }
      metaTitle
      question
      answer {
        answer
      }
    }
  }
`;
