import styled from "styled-components";
import tw from "tailwind-styled-components";

const StyledFaqSearchField = styled.input.attrs({ type: "text" })`
  &:focus {
    outline: none;
  }
`;

export const FaqSearchField = tw(StyledFaqSearchField)`
  ml-[1.125rem]
  text-[19px]
  w-full

  bg-interface-100
  text-interface-400
  placeholder:text-interface-300
`;
