import * as React from "react";
import { Box } from "@components/box";
import { Paragraph, Subheading } from "@components/typography";
import { FaqAnswerProps } from "../types";

export const FaqAnswer: React.FC<FaqAnswerProps> = (props) => (
  <Box className="w-[660px] md:w-full">
    <Subheading className="mb-[2.75rem]">{props.question}</Subheading>
    <Paragraph>
      <div dangerouslySetInnerHTML={{ __html: props.answer }}></div>
    </Paragraph>

    {/* <Box className="border-t-1/2 border-interface-250 mt-[66px] py-[1rem] text-interface-300">
      Did you find this article useful?
      <Button appearance="primary" onClick={() => {}} className="ml-[1.75rem] mr-[11px]">
        Yes
      </Button>
      <Button appearance="secondary" onClick={() => {}}>No</Button>
    </Box> */}
  </Box>
);
