import * as React from "react";
import { Box } from "@components/box";
import { Subheading } from "@components/typography";
import { GatsbyLink } from "@components/gatsby-link";
import { FaqListProps } from "../types";

export const FaqList: React.FC<FaqListProps> = (props) => (
  <Box className="w-[396px] md:w-full">
    <Subheading>
      <GatsbyLink inheritStyle to={`/faq/${props.categorySlug}`}>
        {props.categoryName}
      </GatsbyLink>
    </Subheading>
    <ul className="list-inside list-disc mb-[4.125rem] text-primary mt-[42px]">
      {props.list.map((link, ndx) => (
        <li key={ndx} className="mb-[8px]">
          <GatsbyLink to={`${link.slug}`} className="ml-[-8px]">
            {link.question}
          </GatsbyLink>
        </li>
      ))}
    </ul>
  </Box>
);
